import Swiper from 'swiper';
const galleryThumbnails = document.querySelectorAll('.gallery__thumbnail figure');
const captionsSwiper = document.querySelector('.gallery__captions');
const gallerySwiper = document.querySelector('.gallery__swiper');
let captionsSwiperInstance;
let gallerySwiperInstance;


if (captionsSwiper !== null) {
  captionsSwiperInstance = new Swiper(captionsSwiper, {
    effect: 'fade',
    autoHeight: true
  })
}

if (gallerySwiper !== null) {
  gallerySwiperInstance = new Swiper(gallerySwiper, {
    hashNavigation: {
      watchState: true
    },
    preloadImages: false,
    lazy: {
      loadPrevNext: true
    },
    effect: "fade",
    keyboard: {
      enabled: true
    },
    controller: {
      control: captionsSwiperInstance
    },
    simulateTouch: false,
    navigation: {
      nextEl: ".gallery__navigation__next button",
      prevEl: ".gallery__navigation__prev button"
    },
    on: {
      slideChange: function() {
        document.querySelector(
          ".gallery__navigation__counter__current"
        ).innerText = this.activeIndex + 1;
        galleryThumbnails.forEach(thumb => {
          thumb.classList.remove("active");
          if (
            thumb.dataset.index == this.slides[this.activeIndex].dataset.hash
          ) {
            thumb.classList.add("active");
          }
        });
      }
    }
  });
}


document.querySelectorAll('.gallery__figure img').forEach(img => {
  img.addEventListener('click', e => {
    e.preventDefault;
    gallerySwiperInstance.slideNext();
  })
})

galleryThumbnails.forEach(thumbnail => {
  thumbnail.addEventListener('click', e => {
    window.scrollTo({
      top: 100,
      behavior: 'smooth'
    });
  })
})

document.querySelectorAll('.read-more-button').forEach(cta => {
  cta.addEventListener('click', e => {
    e.preventDefault();
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth'
    })
  })
})
let texts = [...document.querySelectorAll(".fade-in")];

const options = {
  root: null,
  rootMargin: "0px 0px -200px 0px",
  threshold: 0
};

let count = 0;
function onIntersection(texts) {
  texts.forEach(text => {
    if (text.isIntersecting) {
      observer.unobserve(text.target);
      count++;
      setTimeout(() => {
        text.target.classList.remove("hide");
      }, count * 50);
    }
  });
}

let observer = new IntersectionObserver(onIntersection, options);

texts.forEach(text => {
  text.classList.add('hide');
  observer.observe(text)
});
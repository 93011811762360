const bookingButton = document.querySelector('.booking__button--copy');

if (bookingButton !== null) {
  bookingButton.addEventListener('mousemove', e => {
    const toPercent = n => { return n * 100 };
    const mouseXpercentage = e.clientX / window.innerWidth;
    const mouseYpercentage = e.clientY / window.innerHeight;
    if (bookingButton !== null){
      bookingButton.style.background = 'radial-gradient(at ' + toPercent(mouseYpercentage) + '% ' + (toPercent(mouseXpercentage) / 5) + '%, #FFCC99 ' + toPercent(mouseXpercentage) + '%, #CDA656 90%, #ffffff 100%)';
    }
  });
}
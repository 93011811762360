document.querySelectorAll(".lazy-background").forEach(image => {
  const hires = new Image();
  hires.onload = () => {
    if(image.classList.contains('leading-image')){
      const newLeadingImage = image.cloneNode();
      if(window.innerWidth > 768) {
        newLeadingImage.style.backgroundImage = `url(${image.dataset.src})`;
      } else {
        newLeadingImage.style.backgroundImage = `url(${image.dataset.srcMobile})`;
      }
      newLeadingImage.classList.add('leading-image--hi-res');
      image.parentNode.appendChild(newLeadingImage);
      setTimeout(() => {
        newLeadingImage.classList.add('loaded')
      }, 10);
    } else {
      image.style.backgroundImage = `url(${image.dataset.src})`;
    }
  }
  hires.src = image.dataset.src;
});


const resizeWindow = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}
window.addEventListener('resize', () => {
  resizeWindow();
});
resizeWindow();
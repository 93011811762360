import $ from 'jquery';

const hamburger = document.querySelector(".hamburger");
hamburger.addEventListener("click", e => {
  e.preventDefault();
  hamburger.classList.toggle('is-active');
  $('.navigation--mobile__wrapper').fadeToggle(250);
});

const miniMenu = document.querySelector('.navigation--mini');
window.onscroll = e => {
  window.scrollY > window.innerHeight
    ? hamburger.classList.add("black")
    : hamburger.classList.remove("black");
}

